import {TypeCarrierDTO, TypeCarrierEntity} from './carrier.type'
import {useParseData} from '../../../utils/parse.utils'
import {Logger} from '../../../utils/logger.utils'

export const useCarrierUtils = (globalSettings: any) => {
  const route = useRoute()
  const defaultLanguage = globalSettings.languages.filter((ite: any) => ite.default === true)[0].code || 'vi-VI'
  const langCode: string = route.params.langCode || defaultLanguage
  const {generateDefaultFieldDirectus, getTransItemMulti} = useParseData()
  const runtimeConfigs = useRuntimeConfig();
  const logger = Logger(runtimeConfigs.log_level);
  const generateCarrierDto = (item: TypeCarrierEntity, langCodeParam: string = langCode): TypeCarrierDTO | void => {
    try {
      let output = {
        ...generateDefaultFieldDirectus(item),
        carrierId: item.carrierId,
        show: item.show,
        title: getTransItemMulti(item, langCodeParam, 'title')
      }
      return output
    } catch (e: any) {
      logger.error('ERR generateCarrierDto : ', e)
      return {}
    }
  }

  return {
    generateCarrierDto,
    logger
  }
}
